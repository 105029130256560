<template>
  <credentials-layout title="">
    <div class="w-80 mx-auto my-24">
      <div
        class="
          mx-auto
          max-w-md
          px-6
          py-12
          bg-white
          border-0
          shadow-lg
          rounded-3xl
          my-auto
        "
      >
        <h1 class="text-lg font-bold mb-8 text-center">Update password</h1>
        <form id="form" novalidate>
          <div class="relative z-0 w-full mb-5">
            <input
              type="password"
              name="password"
              placeholder=" "
              class="
                pt-3
                pb-2
                block
                w-full
                px-0
                mt-0
                bg-transparent
                border-0 border-b-2
                appearance-none
                focus:outline-none focus:ring-0 focus:border-black
                border-gray-200
              "
              :class="{
                'border-red-600': errors.password,
                'text-red-600': errors.password,
                'animate-wggle': errors.password,
              }"
              @change="validatePassword()"
              v-model="password"
            />
            <label
              for="password"
              class="
                absolute
                duration-200
                top-3
                -z-1
                origin-0
                text-gray-500 text-sm
              "
              >Password</label
            >
            <span class="text-xs font-extralight text-red-600">{{
              errors.password
            }}</span>
          </div>

          <div class="relative z-0 w-full mb-5">
            <input
              type="password"
              name="password"
              placeholder=" "
              class="
                pt-3
                pb-2
                block
                w-full
                px-0
                mt-0
                bg-transparent
                border-0 border-b-2
                appearance-none
                focus:outline-none focus:ring-0 focus:border-black
                border-gray-200
              "
              :class="{
                'border-red-600': errors.password2,
                'text-red-600': errors.password2,
                'animate-wggle': errors.password2,
              }"
              @change="validatePassword2()"
              v-model="password2"
            />
            <label
              for="password"
              class="
                absolute
                duration-200
                top-3
                -z-1
                origin-0
                text-gray-500 text-sm
              "
              >Confirm Password</label
            >
            <span class="text-xs font-extralight text-red-600">{{
              errors.password2
            }}</span>
          </div>

          <button
            id="button"
            type="button"
            class="
              w-full
              px-6
              py-3
              mt-3
              text-base whitespace-nowrap text-white
              transition-all
              duration-150
              ease-linear
              rounded-lg
              font-bold
              shadow
              outline-none
              bg-orange
              hover:shadow-xl
              focus:outline-none
              capitalize
            "
            @click="updatePassword()"
          >
            Update Password
          </button>
        </form>
      </div>
    </div>
    <p class="text-center">Don’t have an account?</p>
    <p class="text-center">
      <n-button
        text
        type="primary"
        class="text-orange"
        @click="() => router.push('/join')"
      >
        Sign Up
      </n-button>
      instead
    </p>
  </credentials-layout>
</template>


<script>
import { NButton } from "naive-ui";
import { useRouter } from "vue-router";
import { mapActions, mapMutations } from "vuex";
import { minLength, required } from "../helpers/validations";
import credentialsLayout from "../components/base/credentialsLayout.vue";

import {
  getAuth,
  updatePassword,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";

export default {
  name: "Login",
  components: { NButton, credentialsLayout },
  data() {
    return {
      username: "",
      password: "",
      password2: "",
      errors: {
        password: null,
        password2: null,
      },
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    ...mapActions(["login_user", "get_user"]),
    ...mapMutations(["setToken", "fireToast"]),
    async updatePassword() {
      this.validatePassword();
      this.validatePassword2();
      if (!this.errors.password && !this.errors.password2) {
        const auth = getAuth();
        if (isSignInWithEmailLink(auth, window.location.href)) {
          // Additional state parameters can also be passed via URL.
          // This can be used to continue the user's intended action before triggering
          // the sign-in operation.
          // Get the email if available. This should be available if the user completes
          // the flow on the same device where they started it.
          let email = window.localStorage.getItem('emailForSignIn');
          if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt('Please provide your email for confirmation');
          }
          // The client SDK will parse the code from the link for you.
          signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
              // Clear email from storage.
              window.localStorage.removeItem('emailForSignIn');
              // You can access the new user via result.user
              // Additional user info profile not available via:
              // result.additionalUserInfo.profile == null
              // You can check if the user is new or existing:
              // result.additionalUserInfo.isNewUser
              const user = auth.currentUser;
              updatePassword(user, this.password).then(() => {
                // Update successful.
                this.fireToast({
                  text: `password updated!`,
                  type: "success",
                });
                this.$router.push('/');
              }).catch((error) => {
                console.error(error)
                // An error ocurred
                const errorCode = error.code;
                this.fireToast({
                  text: `error :( - ${errorCode}`,
                  type: "error",
                });
              });
            })
            .catch((error) => {
              // Some error occurred, you can inspect the code: error.code
              // Common errors could be invalid email and invalid or expired OTPs.
              console.error(error)
              // An error ocurred
              const errorCode = error.code;
              this.fireToast({
                text: `error :( - ${errorCode}`,
                type: "error",
              });
            });
        }
        
      }
    },
    validatePassword() {
      let missing = required(this.password);
      let short = minLength(this.password, 6);
      this.errors.password = missing ? missing : short ? short : null;
    },
    validatePassword2() {
      let equal = this.password == this.password2? null: 'passwords must match!'
      this.errors.password2 = equal;
    },
  },
};
</script>

<style scoped>
.-z-1 {
  z-index: -1;
}

.origin-0 {
  transform-origin: 0%;
}

input:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value=""]):valid ~ label {
  /* @apply transform; scale-75; -translate-y-6; */
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
  --tw-translate-y: -1.9rem;
}

input:focus ~ label,
select:focus ~ label {
  /* @apply text-black; left-0; */
  --tw-text-opacity: 1;
  /* color: rgba(0, 0, 0, var(--tw-text-opacity)); */
  left: 0px;
  color: #ff8303;
}
input:focus {
  border-color: #ff8303;
}
</style>
